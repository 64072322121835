<template>
  <div class="rs-response-pg_container">
    <!-- No Responses Card -->
    <div v-if="!responses">
      <div class="rs-response__no-res-wrap">
        <div class="rs__no-res-card">
          <div><i class="light-icon-clipboard-x"></i></div>
          <div>No Responses</div>
        </div>
      </div>
    </div>
    <!-- Responses Card Wrap -->
    <div v-else>
      <div class="analytics__panel">
        <div class="fs-rd__stats-card --total ">
          <div class="__heading">Total Responses</div>
          <div class="__value">{{ stats.total }}</div>
        </div>
        <!-- Success Responses-->
        <div class="fs-rd__stats-card --success ">
          <div class="__heading">Success Responses</div>
          <div class="__value">{{ stats.success }}</div>
        </div>
        <div class="fs-rd__stats-card --partial ">
          <div class="__heading">Partial Responses</div>
          <div class="__value">{{ stats.partial }}</div>
        </div>
        <!-- Failed Responses -->
        <div class="fs-rd__stats-card --failed ">
          <div class="__heading">Failed Responses</div>
          <div class="__value">{{ stats.failed }}</div>
        </div>
      </div>
      <div class="rs-response__cards-wrap">
        <div class="rs-response_card" style="background-color:#dedede">
          <div class="rs-response_card-content">
            <!-- <div class="rs-response_card_col-1" style="width:20%;">
              Name
            </div> -->
            <div class="rs-response_card_col-2">
              District
            </div>
            <!-- <div class="rs-response_card_col-2" style="width:20%;text-align:center;">
              Cluster
            </div> -->
            <div class="rs-response_card_col-3">
              Status
            </div>
          </div>
        </div>
        <div v-for="(response, responseIndex) in responses" :key="responseIndex" class="rs-response_card" @click="setActiveResponse(responseIndex)">
          <div class="rs-response_card-content">
            <!--Card Column-1 (Name & logo)-->
            <!-- <div class="rs-response-card_col-1">
              <div class="rs-col_content" style="display:flex; gap:8px;align-items:center;">
                <div class="rs-user_avtar">
                  <div v-if="response.response_data['XkEHiEQoirxvwYb6gMZz']">{{ response.response_data['XkEHiEQoirxvwYb6gMZz'][0] }}</div>
                  <i v-else class="light-icon-user"></i>
                </div>
                <div class="rs-response_card-fields">{{ response.response_data['XkEHiEQoirxvwYb6gMZz'] }}</div>
              </div>
              <div class="response_card-vertical-separator"></div>
            </div> -->
            <!--Card Column-2 (Fields) -->
            <div class="rs-response_card_col-3">
              <i :class="activeResponse === responseIndex ? 'light-icon-chevron-up' : 'light-icon-chevron-down'" style="margin-right: 12px"></i>

              <div class="rs-col_content">
                <!-- District -->
                {{ response.response_data['nqDl2ueKwAHeRYHbfnLE'] }}, {{ response.response_data['nl1ObMs5ZpyLWC1fhxqC'] }}
                <!-- <div>{{ response.response_data['y6Z5DAqYnOcqqe1IG7ZV'] }}</div> -->
                <!-- cluster -->
                <!-- <div>{{ response.response_data['nqDl2ueKwAHeRYHbfnLE'] }}</div> -->
              </div>
            </div>
            <!--  -->
            <!-- Card Col-3  -->
            <!-- <div class="rs-response_card_col-3">
              <div class="rs-col_content">
                <div>{{ response.response_data['nqDl2ueKwAHeRYHbfnLE'] }}</div>
              </div>
            </div> -->
            <!--  -->

            <!-- Col-4 -->
            <div class="rs-response_card_col-4">
              <div class="rs-col_content" style="max-width:80px;">
                <!-- <div>Status</div> -->
                <!-- <div class="rs-response_status-check-icon"><i class="light-icon-circle-check"></i></div> -->
                <!-- <div class="rs-response_status-cross-icon"><i class="light-icon-circle-x"></i></div> -->
                <lv-button v-if="response.status === 'pending'" class="lv--danger" push label="Retry" @click="$router.push({ path: '/survey', query: { response: response.response_id } })" />
                <lv-button v-else-if="response.status === 'partial'" class="lv--warning" push label="Continue" @click="$router.push({ path: '/survey', query: { response: response.response_id } })" />
                <lv-button v-else-if="response.status === 'success'" class="lv--success" style="padding:.4rem .8rem;" push label="Success" />
              </div>
            </div>
            <!--  -->
            <!-- Col-5 -->
          </div>
          <lv-collapsible :show="responseIndex === activeResponse">
            <div class="more__fields__card">
              <div class="col-1">
                <div>
                  <span><strong> Panchayat :</strong></span
                  >&nbsp;&nbsp;<span>{{ response.response_data['UP00hal8I0jMOUYZLhtJ'] }}</span>
                </div>
                <br />
                <div>
                  <span><strong> Habitation :</strong></span
                  >&nbsp;&nbsp;<span>{{ response.response_data['iSvs8RxdUK4fTvI1hs0U'] }}</span>
                </div>
                <br />
                <b>FILLED BY:</b><br />
                <div class="username__field" v-if="response && response.other_data && response.other_data.user">
                  <div>{{ response.other_data.user.name }} [{{ response.other_data.user.team_number }}]</div>
                </div>
              </div>
              <div class="col-2">
                <div>
                  <span><strong>Age :</strong></span> <span>{{ response.response_data['9ISITnroYSD4INDzgaQk'] }}</span>
                </div>
                <br />
                <div>
                  <span><strong>Gender :</strong></span> <span>{{ response.response_data['KBDTLyCI42fEnUmAqXhK'] }}</span>
                </div>
              </div>
            </div>
          </lv-collapsible>
        </div>
      </div>
      <lv-button class="lv--primary" @click="exportAll">Export Local Records</lv-button>
      <lv-button class="lv--danger" @click="clearAll">Clear Local Records</lv-button>
    </div>
    <!-- *** -->
  </div>
</template>

<script>
import BottomBar from '../components/BottomBar.vue';
import LvButton from 'lightvue/button';
import { Plugins, FilesystemDirectory, FilesystemEncoding } from '@capacitor/core';

const { Filesystem } = Plugins;
import LvCollapsible from 'lightvue/collapsible';
export default {
  components: { BottomBar, LvButton, LvCollapsible },
  data() {
    return {
      responses: [],
      stats: {
        total: 0,
        success: 0,
        partial: 0,
        failed: 0,
      },
      activeResponse: null,
    };
  },
  created() {
    this.fetchLocalResponses();
  },
  methods: {
    setActiveResponse(responseIndex) {
      this.activeResponse = this.activeResponse === responseIndex ? null : responseIndex;
    },
    fetchLocalResponses() {
      let responsesRaw = localStorage.getItem('responses');
      this.responses = responsesRaw ? JSON.parse(responsesRaw) : [];
      this.stats = {
        total: 0,
        success: 0,
        partial: 0,
        failed: 0,
      };
      // console.log('localstorage responses', this.responses[0].response_data);
      this.responses.forEach(response => {
        this.stats.total++;
        if (response.submission_status === 'partial') {
          this.stats.partial++;
        } else if (response.status === 'pending') {
          this.stats.failed++;
        } else if (response.status === 'success') {
          this.stats.status++;
        }
      });
    },
    async exportAll() {
      try {
        const result = await Filesystem.writeFile({
          path: 'rurban/records.json',
          data: JSON.stringify(this.responses),
          directory: FilesystemDirectory.Documents,
          encoding: FilesystemEncoding.UTF8,
          recursive: true,
        });
        console.log('Wrote file', result);
      } catch (e) {
        console.error('Unable to write file', e);
      }
      let fileName = 'local_responses.json';
      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(this.responses)));
      element.setAttribute('download', fileName);

      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    clearAll() {
      let sure = window.confirm('Are you sure to clear all records from this device? This can not be reversed !!');
      if (sure) {
        window.localStorage.removeItem('responses');
        this.fetchLocalResponses();
      }
    },
  },
};
</script>

<style lang="scss">
.rs-response-pg_container {
  padding: 24px !important;
  max-width: 1100px;
  margin: auto;
}

.rs-response__no-res-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  margin-top: 64px;

  .rs__no-res-card {
    background-color: #ffffff;
    padding: 24px;
    height: 220px;
    width: 380px;
    margin: 12px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    text-align: center;
    cursor: pointer;

    font-size: 20px;
    color: rgba(0, 0, 0, 0.4);
    // color: #203d4a;
    user-select: none;
    border-radius: 4px;

    box-shadow: 16px 16px 32px #e8e8e8, -16px -16px 32px #fff;

    transition: all 0.3s ease-in-out;

    &:active,
    &:hover {
      color: teal;
      box-shadow: 16px 16px 32px #cbcbcb, -16px -16px 32px #fff;
    }
    .lv-ink {
      background-color: #63767e;
    }
    i {
      font-size: 48px;
      margin-bottom: 24px;
    }
  }
}

.rs-response_card {
  min-width: 402px;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  width: 100%;
  padding: 12px;
  margin-bottom: 8px;
}

.rs-response_card:hover {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.rs-response_card-content {
  display: flex;
  width: 100%;
  gap: 16px;
  margin-bottom: 16px;
}

.rs-response-card_col-1 {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rs-response_card_col-2 {
  width: 50%;
}
.rs-response_card_col-3 {
  width: 50%;
  // text-align: center;

  display: flex;
  align-items: center;
}

.rs-response_card_col-4 {
  width: 50%;
}
.rs-col_content {
  width: 100%;
}

.response_card-vertical-separator {
  border-right: 2px solid rgb(110, 107, 106);
}

.rs-user_avtar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(124, 117, 115, 0.274);
  padding: 12px;
  width: 44px;
}

.rs-response_status-check-icon {
  & i {
    font-size: 24px;
    color: rgb(70, 228, 91);
  }
}

.rs-response_status-cross-icon {
  & i {
    font-size: 24px;
    color: rgb(240, 68, 25);
  }
}

.rs-response_vertical-dots {
  padding: 0px 0px 0px 10px;
  & i {
    font-size: 24px;
    // font-weight: 400;
  }
  & :hover {
    cursor: pointer;
    background-color: thistle;
    border-radius: 50%;
  }
}

.username__field {
  background-color: #dedede;
  border-radius: 4px;
  padding: 4px;
  text-align: center;
}
.analytics__panel {
  // padding: 0px 24px 24px 24px;
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
}

.fs-rd__stats-card {
  background-color: #fff;
  text-align: center;
  width: 140px;
  padding: 16px;
  cursor: pointer;
  border-radius: 6px;
  border-bottom: 4px solid #e1e8e8;
  &.--total {
    color: #046077;
    &:hover {
      border-bottom: 4px solid #046077;
    }
  }
  &.--success {
    color: #037f7f;
    &:hover {
      border-bottom: 4px solid #037f7f;
    }
  }
  &.--partial {
    color: #ffa611;
    &:hover {
      border-bottom: 4px solid #ffa611;
    }
  }
  &.--failed {
    color: rgb(248, 127, 106);
    &:hover {
      border-bottom: 4px solid rgb(248, 127, 106);
    }
  }

  // &:hover {
  //   border-bottom: 4px solid #037f7f;
  // }
  .__heading {
    text-align: center;

    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  .__value {
    text-align: center;

    font-size: 48px;
    line-height: 48px;
  }
}

.more__fields__card {
  border-top: 1px solid #dedede;
  padding: 12px;
  // max-width: 400px;
  // margin: auto;
  display: flex;
  gap: 28px;
  .col-1 {
  }
}
</style>
